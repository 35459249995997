.background-container {
    background-image: url("../../../src/media/Banner\ Desktop.png"); /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
    @media (max-width: 768px) {
      /* Adjust breakpoint as needed */
      background-image: url("../../../src/media//2.webp");
      height: 80vh;
    
    }
  }