.container-headereonna {
    display: flex;
    background-color: #2e2e2e;
    height: 125px;
    width: 100%;
    box-shadow: 1px 1px 5px 1px rgb(161, 161, 161);
    @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .logo{
        width: 75%;
        margin-top: 25px;
        padding: 10px;
        @media (max-width: 768px) {
        height: 75px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        }
        .gambareonna{
            width: 75%;
            margin-left: 10%;
            margin-top: -6%;
        @media (max-width: 768px) {
            //margin-top: 10px;
            width: 100%;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin-left: 10px;
            }    
        }
    }
    .container-navbar{
        width: 100%;
      
        .navbar{
            display: flex;
            flex-direction: row;
            justify-content: center;
            .menu-navbar {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                text-align: center;
                width: 100%;
                margin-bottom: -15%;
                @media (max-width: 768px) {
                    margin-top: -10%;
                }
                a {
                  text-decoration: none;
                  color: white;
                  font-family: "Poppins" sans-serif;
                  font-weight: 400px;
                  text-align: center;
                  font-size: 20px;
                  @media (max-width: 768px) {
                    font-size: 15px;
                  }
                  &:hover {
                    color: #f2ad45;
                    border-color: #f2ad45;
                    border-style: solid;
                    border-width: 0px 0px 3px 0px;
                    padding-bottom: 5px;
                  }
                }
                ul {
                  @media (max-width: 768px) {
                    padding-inline-start: 0px;
                  }
                }
                ul li {
                  display: inline-block;
                  list-style-type: none;
                  padding: 30px 20px;
                  text-align: center;
                  @media (max-width: 768px) {
                    padding: 10px 10px;
                  }
                }
            }
        }
    }
    
    .container-buttoneonna {
        @media (max-width: 768px) {
            visibility: hidden;
            width: 0%;
        }
        width: 75%;
        align-items: center;
        justify-content: right;
        display: flex;
        .whatsapp{
            margin-right: 10%;
            display: inline-block;
          padding: 15px 50px;
          cursor: pointer;
          border: none;
          border-radius: 30px;
          border-color: #f2ad45;
          background-color: #f2ad45; /* WhatsApp green */
          color: rgb(237, 237, 237);
          font-size: 15px;
          font-weight: 300px;
          &:hover {
            background-color: transparent;
            border-color: #fbc548;
            color: #fbc548;
            border-width: 1px;
            border-style: solid;
        }
    }
    }
}