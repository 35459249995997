.cluster{
    margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
  }
  .devider-cluster {
    margin-top: 50px;
    background-color: #2e2e2e;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .judul-cluster {
      width: 100%;
      text-align: center;
      color: white;
      font-weight: 400;
      font-size: 40px;
      line-height: 1em;
      font-family: "Poppins", sans-serif;
      @media (max-width: 768px) {
        font-size: 25px;
      }
    }
    .carousel-logocluster {
      width: 50%;
      display: flex;
      justify-content: center;
      @media (max-width: 768px) {
        width: 98%;
        margin-top: 10px;
      }
      .slick-slider {
        width: 70%;
        margin: auto;
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-list {
          border-radius: 10px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides / / Adjust vertical positioning */
          left: 50%;
          bottom: -15px; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }
    }
  }
}