.judulpromo {
    display: flex;
    background-color: #2e2e2e;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -100px;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      margin-top: 10px;
    }
    .judul {
      font-weight: 600;
      font-size: 30px;
      color: #FBBF68;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
  
  .containerPenawaran {
    margin-top: 30px;
    height: 100%;
    width: 100%;
    display: flex;
    margin-bottom: 50px;
    justify-content: flex-end; /* Added justify-content */
    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 0;
    }
    .containergambar {
      order: 1;
      width: 50%;
      text-align: right;
      margin-top: -20px;
      @media (max-width: 768px) {
        margin-top: 25px;
        order: 1;
        width: 100%;
        text-align: center;
      }
  
      .penawarangambar {
        width: 500px;
        height: 650px;
        align-self: flex-end;
        box-shadow: 0 0 10px 0;
        border-radius: 5px;
        @media (max-width: 768px) {
          width: 90%;
          height: 100%;
        }
      }
    }
  
    .containercontent {
      order: 2;
      width: 50%;
      margin-left: 100px;
      text-align: left;
      justify-content: center;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        margin-left: 25px;
        width: 90%;
        order: 2;
        margin-top: 15px;
      }
    }
    .disclaimer {
      margin-top: 25px;
      font-size: 13px;
      color: white;
    }
    .pointpenawaran {
      flex-direction: row;
      font-size: 30px;
      @media (max-width: 768px) {
        font-size: 25px;
        width: 100%;
      }
      .penawaranpoin {
        margin-top: 0px;
        color: #FBBF68;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .buttonpenawaran {
      color: white;
      margin-top: 30px;
      background-color: #f2ad45;
      width: 50%;
      padding: 15px 30px;
      border: 1px solid white; /* Add this line for black border */
      border-color: #C88C39;
      border-radius: 25px;
      font-size: 16px;
      font-weight: light;
      cursor: pointer;
      display: inline-block;
      transition: background-color 0.3s ease, color 0.3s ease;
      @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
        margin-left: 15%;
        width: 70%;
      }
      &:hover {
        background-color: transparent;
        color: #f2ad45;
        border-color: #f2ad45;
      }
      
    }
    .slider1{
        width: 100%;
    }
  }