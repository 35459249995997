@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.rumah{
  .container-header1{
    margin-top: -150px;
    @media (max-width: 768px){
      margin-top: -50px;
    }
  }
  .containercard2 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      width: 100%;
      padding-bottom: 10px;
      margin-top: -120px;
      @media (max-width: 768px) {
        flex-direction: column;
        overflow: hidden;
        margin-bottom: -60px;
        margin-top: 40px;
      }
      .card {
        width: 23.5%;
        margin-left: 15px;
        margin-top: 10px;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 0 0 10px 0;
        padding-bottom: 10px;
        hr {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 15px;
          width: 90%;
        }
        @media (max-width: 768px) {
          width: 90%;
          margin-bottom: 20px;
          margin-left: 0px;
          height: auto;
        }
        .imagecard {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }
        .imagecard1 {
          width: 100%;
        }
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-list {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }
  
        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */
          bottom: 10px; /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
  
        .container-judul-kartu {
          width: 100%;
          margin-top: 10px;
          //display: flex;
          //flex-direction: row;
  
          .judulkartu {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .nama-rumah {
              color: #C88C39;
              font-family: "Poppins";
              font-size: 30px;
              font-weight: 500;
            }
          }
          .cicilan {
            width: 100%;
            color: #4e4e4e;
            align-content: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: -10px;
            .angka {
              font-family: "Poppins" sans-serif;
              font-size: 15px;
              font-weight: 700px;
            }
            .ket {
              font-family: "Poppins" sans-serif;
              font-size: 15px;
              margin: 8px;
              font-weight: 700px;
            }
          }
        }
        .gridspek {
          padding-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
  
          span {
            color: #4e4e4e;
            font-size: 14px;
              display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 8px;
            padding-right: 15px;
            @media (max-width: 768px) {
              padding-right: 20px;
              font-size: 12px;
            }
          }
        }
        .containerwhatsapp {
          display: flex;
          justify-content: center;
          padding-top: 25px;
          .whatsapp {
            display: inline-block;
            padding: 10px 100px;
            cursor: pointer;
            border: 1px solid #f5b049;
            border-radius: 15px;
            background-color: #ffb445; /* WhatsApp green */
            color: white;
            font-size: 16px;
            &:hover {
              background-color: transparent;
              color: #f5b049;
              border-color: #f5b049;
              cursor: pointer;
            }
          }
        }
        
      }
    }  
}

